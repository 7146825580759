import React from "react";
import Label from "./Label";

/**
 * @typedef {Object} Option
 *
 * @property {string} label - Label of option.
 * @property {string} value - Value of option.
 */

/**
 * Select React Component
 *
 * @param {Array<Option>} options - Options of select element
 * @param {Option} value - Current value of select element
 * @param {string} label - Label of select element
 * @param {function} onChange - onchange event handler
 * @param {string} customStyles - Inline style for the select element
 * @return {JSX.Element}
 * @constructor
 */
const Select = ({ options, value, label, onChange, customStyles, ...rest }) => {
  if (!options.length) {
    throw new Error("missing options");
  }

  return (
    <div className="flex flex-col mb-1">
      {label && <Label>{label}</Label>}
      <select
        style={customStyles}
        className="mt-1 px-2 py-2 pr-10 border shadow-sm focus:ring-am-500 focus:border-am-500 block w-full sm:text-sm border-gray-300 rounded-3xl disabled:opacity-50"
        value={value}
        onChange={onChange}
        {...rest}
      >
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label !== undefined ? option.label : option.name}
          </option>
        ))}
      </select>
    </div>
  );
};
export default Select;
