import { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const HeaderContext = createContext(null);

export const useHeaderContext = () => {
  const context = useContext(HeaderContext);

  if (!context) {
    throw new Error("useHeaderContext must be used within a HeaderProvider");
  }

  return context;
};

export const HeaderProvider = ({ children }) => {
  /* Set the page title The title will be overwritten from PageHeading component, if present */
  const [defaultPageTitle] = useState(document.title);
  const location = useLocation();

  useEffect(() => {
    // reset at each location change; it will be overwritten from useSetPageTitle if present
    document.title = defaultPageTitle;
  }, [defaultPageTitle, location]); // runs when location changes

  return (
    <HeaderContext.Provider value={{ defaultPageTitle }}>
      {children}
    </HeaderContext.Provider>
  );
};

export const useSetPageTitle = (title) => {
  const { defaultPageTitle } = useHeaderContext();
  
  setTimeout(() => {
    document.title = title + " - " + defaultPageTitle;
  }, 0);
};
