import React from "react";
import Select from "../../Select";
import Spinner from "../../Spinner";
import useRoles from "./hooks/useRoles";

const SelectRole = ({ value, onChange }) => {
  const roles = useRoles();

  if (!roles) {
    return (
      <div className="flex w-full justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <Select
      options={roles.map((r) => ({ value: r.id, name: r.name }))}
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
    />
  );
};

export default SelectRole;
